





















import { Component, ModelSync, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

@Component({
  components: {
    ValidationProvider
  }
})
export default class PasswordConfirmField extends Mixins(ValidatorConfigMixin) {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: String }) label!: string
}
