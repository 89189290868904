































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import snakecaseKeys from 'snakecase-keys'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class GenericModalDelete extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ required: true }) requestUrl!: string
  @Prop() requestBody!: Record<string, any>
  @Prop({ default: 'delete', type: String }) verb!: 'patch' | 'delete'

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  closeModal () {
    this.$emit('close')
  }

  submit () {
    if (this.verb === 'delete') {
      axios.delete(this.requestUrl, { data: snakecaseKeys({ ...this.form, ...this.requestBody }, { deep: true }) })
        .then(response => {
          this.$emit('submitted', response.data.data)
        })
        .catch(err => this.handleSubmitError(err))
    } else if (this.verb === 'patch') {
      axios.patch(this.requestUrl, snakecaseKeys({ ...this.form, ...this.requestBody }, { deep: true }))
        .then(response => {
          this.$emit('submitted', response.data.data)
        })
        .catch(err => this.handleSubmitError(err))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }

    if (response.status === 403) {
      this.$emit('error', response.data.data)
    }
  }

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  }

  @Watch('visible')
  onVisibleChange (value: boolean) {
    if (value === true) {
      this.clearForm()
    }
  }

  clearForm () {
    this.form = {
      confirm: '',
      errors: {
        confirm: ''
      }
    }

    this.$refs.observer.reset()
  }
}
