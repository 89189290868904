import { Component, Vue } from 'vue-property-decorator'
import { extend } from 'vee-validate'
import { required, email, confirmed, max_value as maxValue, min_value as minValue, numeric } from 'vee-validate/dist/rules'

@Component
export default class ValidatorConfigMixin extends Vue {
  created () {
    this.validations()
  }

  validations () {
    extend('required', {
      ...required,
      message: 'O campo {_field_} é obrigatório.'
    })

    extend('email', {
      ...email,
      message: 'O campo email deve ser um email válido.'
    })

    extend('confirmed', {
      ...confirmed,
      message: 'Os campos {_field_} não são iguais.'
    })

    extend('max_value', {
      ...maxValue,
      message: 'O campo {_field_} deve ser {max} ou menor.'
    })

    extend('min_value', {
      ...minValue,
      message: 'O campo {_field_} deve ser {min} ou maior.'
    })

    extend('numeric', {
      ...numeric,
      message: 'O campo numérico {_field_} é obrigatório.'
    })

    extend('password', {
      params: ['target'],
      validate (value, { target }: Record<string, any>) {
        return value === target
      },
      message: 'Password confirmation does not match'
    })
  }
}
